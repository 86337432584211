import React, { useEffect, useState } from "react";
import Styles from "./AlarmList.module.css";
import { getAllPredictions, getPredictionResponse } from "../api/getPrediction";
import {regionNameMap} from "../constants";

const AlarmList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [alarms, setAlarms] = useState<getPredictionResponse[]>([]);

    useEffect(() => {
        setIsLoading(true);
        getAllPredictions()
            .then((response) => {
                setAlarms(response.predictions);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={Styles.alarmContainer}>
            <h1 className={Styles.alarmTitle}>
                СПИСОК ПЕРЕДБАЧЕНЬ
            </h1>
            <div className={Styles.alarmCardDivider}></div>

            <div className={Styles.scrollableList}>
                {isLoading ? (
                    <div className={Styles.loading}>Loading...</div>
                ) : (
                    alarms.map((alarm, index) => (
                        <div key={index} className={Styles.alarmCard}>
                            <div className={Styles.alarmCardLeftBorder}></div>
                            <p className={Styles.alarmCardTitle}>{regionNameMap[alarm.regionCode]}</p>
                            <div className={Styles.alarmCardDivider}></div>
                            <p>{alarm.predictionDateTime}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default AlarmList;
