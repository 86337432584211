import React from 'react';
import Modal from "./Modal";
import Styles from './MistakeHistory.module.css';
import {Mistake} from "../api/getMistakeHistory";
import {regionNameMap} from "../constants";


interface MistakeHistoryModalProps {
    isVisible: boolean;
    onClose: () => void;
    mistakes: Mistake[];
    isLoading: boolean;
}

const MistakeHistory = ({ isVisible, onClose, mistakes, isLoading }: MistakeHistoryModalProps) => {
    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            {isLoading ? (
                <p>Загрузка...</p>
            ) : (
                <div className={Styles.historyList}>
                    {mistakes.length === 0 ? (
                        <p>Історія помилок відсутня</p>
                    ) : (
                        mistakes.map((mistake, index) => (
                            <div key={index} className={Styles.historyCard}>
                                <p className={Styles.historyCardTitle}>{regionNameMap[mistake.region]}</p>
                                <p>Час тривоги: {mistake.alarm_time}</p>
                                <div className={Styles.historyCardDivider}></div>
                                <p>Передбачуваний час тривоги: {mistake.predicted_alarm_time}</p>
                                <p>Помилка: {mistake.mistake}</p>
                            </div>
                        ))
                    )}
                </div>
            )}
        </Modal>
    );
};

export default MistakeHistory;
