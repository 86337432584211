export const monthNameMap: { [key: string]: string } = {
    '01': 'січня',
    '02': 'лютого',
    '03': 'березня',
    '04': 'квітня',
    '05': 'травня',
    '06': 'червня',
    '07': 'липня',
    '08': 'серпня',
    '09': 'вересня',
    '10': 'жовтня',
    '11': 'листопада',
    '12': 'грудня'
}

export const regionNameMap: { [key: string]: string } = {
    'Zakarpatska oblast': 'Закарпатська область',
    'Lvivska oblast': 'Львівська область',
    'Ivano-Frankivska oblast': 'Івано-Франківська область',
    'Chernivetska oblast': 'Чернівецька область',
    'Volynska oblast': 'Волинська область',
    'Rivnenska oblast': 'Рівненська область',
    'Ternopilska oblast': 'Тернопільська область',
    'Khmelnytska oblast': 'Хмельницька область',
    'Zhytomyrska oblast': 'Житомирська область',
    'Vinnytska oblast': 'Вінницька область',
    'Sumska oblast': 'Сумська область',
    'Chernihivska oblast': 'Чернігівська область',
    'Kyivska oblast': 'Київська область',
    'Kyiv City': 'м. Київ',
    'Odeska oblast': 'Одеська область',
    'Crimea': 'АР Крим',
    'Luhanska oblast': 'Луганська область',
    'Donetska oblast': 'Донецька область',
    'Kharkivska oblast': 'Харківська область',
    'Poltavska oblast': 'Полтавська область',
    'Dnipropetrovska oblast': 'Дніпропетровська область',
    'Kirovohradska oblast': 'Кіровоградська область',
    'Zaporizka oblast': 'Запорізька область',
    'Khersonska oblast': 'Херсонська область',
    'Mykolaivska oblast': 'Миколаївська область',
    'Cherkaska oblast': 'Черкаська область',
}
