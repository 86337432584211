import React, {useState} from 'react';
import Style from './App.module.css';
import Ukraine from "./UkraineMap";
import AlarmList from "./components/AlarmList";
import MistakeHistory from "./components/MistakeHistory";
import {getMistakeHistory, Mistake} from "./api/getMistakeHistory";

function App() {
    const [isGlobalModalVisible, setIsGlobalModalVisible] = useState(false);
    const [isGlobalHistoryLoading, setIsGlobalHistoryLoading] = useState(false);
    const [globalMistakeHistory, setGlobalMistakeHistory] = useState<Mistake[]>([]);

    async function getGlobalMistakeHistory() {
        setIsGlobalHistoryLoading(true);
        setIsGlobalModalVisible(true);
        try {
            const mistakeHistory = await getMistakeHistory();
            setGlobalMistakeHistory(mistakeHistory.mistakeHistory);

        } catch (error) {
            console.error('Помилка отримання даних:', error);
        } finally {
            setIsGlobalHistoryLoading(false);
        }
    }

    return (
        <div className={Style.App}>
            <div className={Style.titleWrapper}>
                <h1 className={Style.title}>ПЕРЕДВІСНИК ПОВІТРЯНИХ ТРИВОГ</h1>
            </div>
            <div className={Style.background}></div>
            <button
                className={Style.historyButton}
                onClick={getGlobalMistakeHistory}
            >
                Історія передбачень
            </button>
            <div className={Style.contentWrapper}>
                <Ukraine/>
                <AlarmList/>
            </div>
            <MistakeHistory
                mistakes={globalMistakeHistory}
                isVisible={isGlobalModalVisible}
                isLoading={isGlobalHistoryLoading}
                onClose={() => setIsGlobalModalVisible(false)}
            />
        </div>
    );
}

export default App;
