import {convertDate} from "./utils";

interface getPredictionProps {
    regionCode: string;
}

export interface getPredictionResponse {
    predictionDateTime: string;
    regionCode: string;
}

interface getAllPredictionsResponse {
    predictions: getPredictionResponse[];
}


export async function getPrediction({regionCode}: getPredictionProps): Promise<getPredictionResponse> {

    if (regionCode === null) {
        return {
            predictionDateTime: "No data",
            regionCode: regionCode
        };
    }

    const body = {
        "regionCode": regionCode,
    }

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }

    const response = await fetch('/api/predict', options);
    const data = await response.json();

    const PredictionDateTime = data["next_event_time"];
    const readableDate = convertDate(PredictionDateTime);
    return {
        predictionDateTime: readableDate,
        regionCode: regionCode
    }
}

export async function getAllPredictions(): Promise<getAllPredictionsResponse> {


    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const response = await fetch('/api/predictions', options);
    const data = await response.json();

    const predictions = data.predictions.map((prediction: any) => {
        const PredictionDateTime = prediction["next_event_time"];
        const readableDate = convertDate(PredictionDateTime);
        return {
            predictionDateTime: readableDate,
            regionCode: prediction["region_code"]
        }
    });

    return {
        predictions: predictions
    }
}
