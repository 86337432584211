import {convertDate} from "./utils";

export interface AlarmRegion {
    region_code: string;
    alarm_time: string;
    predicted_datetime: string;
    predicted_time_to_next_alarm: number;
}

interface getActiveAlarmRegionsResponse {
    activeAlarmRegions: AlarmRegion[];
}

export async function getActiveAlarmRegions(): Promise<getActiveAlarmRegionsResponse> {

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    const response = await fetch('/api/actual_alarms', options);
    const data = await response.json();

    let activeAlarmRegions = data.active_alarms;

    for (let i = 0; i < activeAlarmRegions.length; i++) {
        activeAlarmRegions[i].predicted_datetime = convertDate(activeAlarmRegions[i].predicted_datetime);
    }

    return {
        activeAlarmRegions: activeAlarmRegions
    };
}
