import {convertDate} from "./utils";

export interface Mistake {
    region: string;
    alarm_time: string;
    predicted_alarm_time: string;
    mistake: string;
}

interface getMistakesResponse {
    mistakeHistory: Mistake[];
}

export async function getMistakeHistory(): Promise<getMistakesResponse> {

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    };

    const response = await fetch('/api/alarm_mistakes/', options);
    const data = await response.json();

    const mistakeHistory = data.alarm_mistakes;

    for (let i = 0; i < mistakeHistory.length; i++) {
        mistakeHistory[i].alarm_time = convertDate(mistakeHistory[i].alarm_time);
        mistakeHistory[i].predicted_alarm_time = convertDate(mistakeHistory[i].predicted_alarm_time);
    }

    return {
        mistakeHistory: mistakeHistory
    };
}

export async function getMistakeHistoryByRegion(region: string): Promise<getMistakesResponse> {
        const regionForUrl = region.replace(" ", "%20");
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };

        const response = await fetch(`/api/alarm_mistakes/${regionForUrl}`, options);
        const data = await response.json();
        const mistakeHistory = data.alarm_mistakes;

        for (let i = 0; i < mistakeHistory.length; i++) {
            mistakeHistory[i].alarm_time = convertDate(mistakeHistory[i].alarm_time);
            mistakeHistory[i].predicted_alarm_time = convertDate(mistakeHistory[i].predicted_alarm_time);
        }

        return {
            mistakeHistory: mistakeHistory
        };
    }

