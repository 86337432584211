import React, {useEffect, useRef, useState} from 'react';
import {drawPath, stateCode, constants, viewBox} from './constants';
import useMousePosition from './hooks/mouseTrack';
import {getPrediction} from "../api/getPrediction";
import {AlarmRegion, getActiveAlarmRegions} from "../api/getActiveAlarmRegions";
import {getMistakeHistoryByRegion, Mistake} from "../api/getMistakeHistory";
import Styles from "./Ukraine.module.css";
import MistakeHistory from "../components/MistakeHistory";
import {regionNameMap} from "../constants";

const Ukraine = () => {
    const [alarmedRegions, setAlarmedRegions] = useState<AlarmRegion[]>([]);
    const {x, y} = useMousePosition();
    const [stateHovered, setStateHovered] = useState<string | null>(null);
    const [predictMessage, setPredictMessage] = useState<React.ReactNode | null>(null);
    const [isPredictionLoading, setIsPredictionLoading] = useState<boolean>(false);
    const [isRegionHistoryLoading, setIsRegionHistoryLoading] = useState<boolean>(false);
    const [regionMistakeHistory, setRegionMistakeHistory] = useState<Mistake[]>([]);
    const [isAlarmListLoading, setIsAlarmListLoading] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const touchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (isAlarmListLoading) {
            getActiveAlarms();
            setIsAlarmListLoading(false);
        } else {
            setAlarmedRegions([]);
        }

        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    useEffect(() => {
        function handleOutsideClick(event: MouseEvent) {
            const mapElements = document.querySelectorAll('#svg2 g');
            let clickedInside = false;

            mapElements.forEach((element) => {
                if (element.contains(event.target as Node)) {
                    clickedInside = true;
                }
            });

            if (!clickedInside) {
                setStateHovered(null);
            }
        }

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (stateHovered) {
            setIsPredictionLoading(true);
            getPredictionDateTime(stateHovered);
        } else {
            setPredictMessage(null);
        }
    }, [stateHovered]);

    async function onRegionClick(regionCode: string) {
        setIsRegionHistoryLoading(true);
        setIsModalVisible(true);
        try {
            const regionMistakeHistory = await getMistakeHistoryByRegion(regionCode);
            setRegionMistakeHistory(regionMistakeHistory.mistakeHistory);

        } catch (error) {
            console.error('Помилка отримання даних:', error);
        } finally {
            setIsRegionHistoryLoading(false);
        }
    }

    async function getPredictionDateTime(regionCode: string) {
        try {
            if (regionCode === 'Luhanska oblast' || regionCode === 'Crimea') {
                setPredictMessage("Дані відсутні");
                setIsPredictionLoading(false);
                return;
            }

            const alarmRegion = alarmedRegions.find(region => region.region_code === regionCode);
            if (alarmRegion) {
                setPredictMessage(
                    <div>
                        <p>Регіон: {regionNameMap[alarmRegion.region_code]}</p>
                        <p>Час тривоги: {alarmRegion.alarm_time}</p>
                        <p>Передбачуваний час наступної тривоги: {alarmRegion.predicted_datetime}</p>
                        <p>Помилка: {alarmRegion.predicted_time_to_next_alarm}</p>
                    </div>
                );
                return;
            }
            const prediction = await getPrediction({regionCode});
            setPredictMessage(
                <div>
                    <p>Регіон: {regionNameMap[prediction.regionCode]}</p>
                    <p>Час наступної тривоги: {prediction.predictionDateTime}</p>
                </div>
            );
        } catch (error) {
            console.error('Помилка отримання даних:', error);
            setPredictMessage("Помилка отримання даних");
        } finally {
            setIsPredictionLoading(false);
        }
    }

    async function getActiveAlarms() {
        try {
            const activeAlarms = await getActiveAlarmRegions();
            setAlarmedRegions(activeAlarms.activeAlarmRegions);
        } catch (error) {
            console.error('Помилка отримання даних:', error);
        }
    }

    function handleTouchStart(regionCode: string) {
        touchTimeoutRef.current = setTimeout(onRegionClick, 500, regionCode);
    }

    function handleTouchEnd(regionCode: string) {
        if (touchTimeoutRef.current) {
            clearTimeout(touchTimeoutRef.current);
            setStateHovered(regionCode);
        }
    }

    const handleMouseEnter = (hoverStateId: string) => {
        if (!isMobile) {
            const path = document.getElementById(hoverStateId);
            if (path) {
                const alarmRegion = alarmedRegions.find(region => region.region_code === hoverStateId);
                path.style.fill = alarmRegion ? constants.SELECTED_COLOR : constants.HOVERCOLOR;
            }
            setStateHovered(hoverStateId);
        }
    };

    const handleMouseLeave = (hoverStateId: string) => {
        if (!isMobile) {
            const path = document.getElementById(hoverStateId);
            if (path) {
                const alarmRegion = alarmedRegions.find(region => region.region_code === hoverStateId);
                path.style.fill = alarmRegion ? constants.SELECTED_COLOR : constants.MAPCOLOR;
            }
            setStateHovered(null);
        }
    };

    return (
        <>
            <div className={Styles.map}>
                <svg version="1.1" id="svg2" x="0px" y="0px" viewBox={viewBox} style={{position: "relative"}}>
                    {stateCode?.map((stateCode, index) => (
                        <g
                            key={index}
                            onMouseEnter={() => handleMouseEnter(stateCode)}
                            onMouseLeave={() => handleMouseLeave(stateCode)}
                            onClick={!isMobile ? () => onRegionClick(stateCode) : undefined}
                            onTouchStart={() => handleTouchStart(stateCode)}
                            onTouchEnd={() => handleTouchEnd(stateCode)}
                            style={
                                alarmedRegions.find(region => region.region_code === stateCode) ?
                                    {filter: 'url(#glow)'}
                                    :
                                    {}
                            }
                        >
                            <path
                                fill={stateCode === 'Luhanska oblast' || stateCode === 'Crimea'
                                    ? 'red'
                                    : alarmedRegions.find(region => region.region_code === stateCode)
                                        ? constants.SELECTED_COLOR
                                        : constants.MAPCOLOR}
                                id={stateCode}
                                d={drawPath[stateCode].d}
                                stroke="#000"
                            />
                            <text
                                className={Styles.regionTitle}
                                fill='#000000'
                                strokeWidth="0.1"
                                xmlSpace="preserve"
                                style={{whiteSpace: 'nowrap'}}
                                fontSize={drawPath[stateCode].fontSize || 20}
                                x={drawPath[stateCode].titleX}
                                y={drawPath[stateCode].titleY}
                            >
                                {drawPath[stateCode].title}
                            </text>
                        </g>
                    ))}
                    <defs>
                        <filter id="glow">
                            <feGaussianBlur stdDeviation="3.5" result="coloredBlur"/>
                            <feMerge>
                                <feMergeNode in="coloredBlur"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                </svg>
            </div>

            <div>
                {stateHovered && (
                    <div
                        style={{
                            position: 'absolute',
                            top: y + (isMobile ? 0 : 20) > window.innerHeight - 100 ? window.innerHeight - 100 : y + (isMobile ? 0 : 20),
                            left: x + (isMobile ? 0 : 20) > window.innerWidth - 150 ? window.innerWidth - 150 : x + (isMobile ? 0 : 20),
                            zIndex: 10,
                        }}
                        className={Styles.hint}
                    >
                        {isPredictionLoading ? 'Загрузка...' : predictMessage}
                    </div>
                )}
            </div>

            <MistakeHistory
                isVisible={isModalVisible}
                mistakes={regionMistakeHistory}
                isLoading={isRegionHistoryLoading}
                onClose={() => setIsModalVisible(false)}
            />
        </>
    );
};

export default Ukraine;
