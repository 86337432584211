import Styles from "./Modal.module.css";
import React, {useEffect, useRef} from "react";

interface ModalProps {
    isVisible: boolean;
    onClose: () => void;
    children: React.ReactNode;
}


const Modal = ({isVisible, onClose, children}: ModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isVisible) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isVisible, onClose]);

    if (!isVisible) return null;

    return (
        <div className={Styles.modalBackdrop}>
            <div className={Styles.modalContent} ref={modalRef}>
                <button className={Styles.closeButton} onClick={onClose}>
                    ×
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
