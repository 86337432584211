import { useState, useEffect } from "react";

const useMousePosition = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const updateMousePosition = (event: any) => {
        setPosition({ x: event.clientX, y: event.clientY });
    };

    useEffect(() => {
        window.addEventListener("mousemove", updateMousePosition);
        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);

    return position;
};

export default useMousePosition;
